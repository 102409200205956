<template>
    <div>
        <div class="header" id="header">
            <img src="https://zhongyinwh.oss-cn-beijing.aliyuncs.com/scott/pic/组763_1686894590711.png" class="hwlogo" @click="toindex">
            <div class="headericon" id="headericon" @click="headericon"></div>
        </div>
        <div class="headerhidden" id="headerhidden">
            <div class="headeritem" @click="toindex"> <span> 首页 </span> </div>
            <div class="headeritem" v-for="(item) in columnData" :key="item.id" @click="toLink(item.url)">
                <span>{{item.columnName}}</span>
            </div>
        </div>
        <div style="height: 1.08rem;"></div>


        


        <keep-alive>
            <router-view></router-view>
        </keep-alive>

        <Foot />

        <div class="toGrzx" @click="toGrzx">
            <img src="https://zhongyinwh.oss-cn-beijing.aliyuncs.com/scott/pic/个人中心(1)(1)_1686566476728.png" class="toGrzxImg">
        </div>


    </div>
</template>



<script>
import Foot from "@/components/foot.vue"
import Cookies from 'js-cookie';
import {getListColumn} from "@/api/index"
import store from '@/store'
export default {
    components:{
        Foot
    },
    data () {
        return {
            headerflag : false,
            hiddenflag : false,
            columnData:null,
        }
    },
    computed: {
        columnList() {
            return this.$store.getters.columnList
        },
    },
    methods:{
        toGrzx(){
            if(!Cookies.get('wjsToken')){
                this.$router.push({name:'dl'});
            }else{
                this.$router.push({name:'grzx'});
            }
        },
        toLink(name){
            //if(name == "yszb"){
                //window.location.href="http://www.zygart.cn"; 
            //}else{
                this.reset()
                this.$router.push({name:name});
            //}
        },
        toBack(){
            this.reset()
            // if(this.$route.params.linkType && this.$route.params.linkType == 1){
            //     this.$router.push({name:'index'});
            // }else if(this.$route.params.linkType && this.$route.params.linkType == 2){
            //     this.$router.push({name:'movielist'});
            // }else{
            //     this.$router.push({name:'index'});
            // }
            this.$router.back(-1)
        },
        toyszb(){
            this.reset()
            this.$router.push({name:'yszb'});
        },
        tojrpf(){
            this.reset()
            this.$router.push({name:'jrpf'});
        },
        tohzjg(){
            this.reset()
            this.$router.push({name:'hzjg'});
        },
        tobsxw(){
            this.reset()
            this.$router.push({name:'bsxw'});
        },
        tozcfg(){
            this.reset()
            this.$router.push({name:'zcfg'});
        },
        toindex(){
            this.reset()
            this.$router.push({name:'index'});
        },
        toaboutus(){
            this.reset()
            this.$router.push({name:'gywm'});
        },
        tonews(){
            this.reset()
            this.$router.push({name:'hydt'});
        },
        tonews2(){
            this.reset()
            document.body.scrollTop = document.documentElement.scrollTop = 0
            this.$router.push({name:'news'});
        },
        togsdt(){
            this.reset()
            this.$router.push({name:'gsdt'});
        },
        toyszx(){
            this.reset()
            this.$router.push({name:'yszx'});
        },
        tohyfx(){
            this.reset()
            this.$router.push({name:'hyfx'});
        },
        tozcjd(){
            this.reset()
            this.$router.push({name:'zcjd'});
        },
        tomovielist(){
            this.reset()
            this.$router.push({name:'xmpl'});
        },
        tongyhd(){
            this.reset()
            this.$router.push({name:'gyhd'});
        },
        touserguide(){
            this.reset()
            this.$router.push({name:'userGuide'});
        },
        tocu(){
            this.reset()
            this.$router.push({name:'connectus'});
        },
        headericon(){
            var headericon = document.getElementById("headericon")
            var headerhidden = document.getElementById("headerhidden")
            if(this.headerflag){
                headerhidden.style.top = "-100%"
                headericon.classList.remove("headericon2")
                headericon.classList.add("headericon")
                this.headerflag = false
            }else{
                headerhidden.style.top = "1.06rem"
                headericon.classList.remove("headericon")
                headericon.classList.add("headericon2")
                this.headerflag = true
            }
        },
        headjt(){
            var headjt = document.getElementById("headjt")
            var hiddenbox = document.getElementById("hiddenbox")
            if(this.hiddenflag){
                hiddenbox.style.height = "0"
                headjt.style.transform = "rotateZ(0deg)"
                this.hiddenflag = false
            }else{
                hiddenbox.style.height = "4rem"
                headjt.style.transform = "rotateZ(-180deg)"
                this.hiddenflag = true
            }
        },
        reset(){
            this.headerflag = false
            this.hiddenflag = false
            var headericon = document.getElementById("headericon")
            var headerhidden = document.getElementById("headerhidden")
            // var headjt = document.getElementById("headjt")
            // var hiddenbox = document.getElementById("hiddenbox")
            headerhidden.style.top = "-100%"
            headericon.classList.remove("headericon2")
            headericon.classList.add("headericon")
            // hiddenbox.style.height = "0"
            // headjt.style.transform = "rotateZ(0deg)"
        }
    },
    mounted(){
        if(this.columnList){
            console.log("store" + this.columnList)
            this.columnData = this.columnList;
            this.load && this.load.close();
        }else{
            getListColumn().then(res => {
                //console.log(res)
                this.columnData = res.result;

                store.dispatch('SetColumnList', res.result)

            })
        }

    }
}
</script>




<style lang="scss" scoped>

</style>